html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  max-width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  animation: smoothScroll 1s forwards;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: IBMPlexSans;
  src: url(./assets/fonts/IBMPlexSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Kalam;
  src: url(./assets/fonts/Kalam.ttf) format("truetype");
}

@font-face {
  font-family: IBMPlexSansBold;
  src: url(./assets/fonts/IBMPlexSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: Poppins-Bold;
  src: url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}
